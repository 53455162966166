import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./video.css";
//import Player from "../../assets/img/home/video.png";
//import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const Video = () => {
  const [t] = useTranslation("global");
  return (
    <div id="video">
      <div className="video">
        <Container fluid>
          <Row>
            {/*<Col xs={12} md={6}>
              <Fade clear duration={2000}>
                <Image className="float-right" alt="Comming soon" src={Player} />
              </Fade>
            </Col>*/}
            <Col xs={12} md={12}>
              <Fade clear duration={2000}>
                <h1>{t("video.phrase1")}<br></br>{t("video.phrase2")}<br></br>{t("video.phrase3")}<b className="text-danger">!</b></h1>
                <div className="d-flex align-items-center justify-content-center text-center not-found-container">
                  <Button variant="danger" size="lg" className="boton">
                    <ArrowDownwardIcon/>
                  </Button>  
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Video;