import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./bullyng.css";
import BullyImg from "../../assets/img/bullyng/bullying.png";
import pdf from "../../assets/img/bullyng/otts bullying prevention and martial arts.pdf";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Bullyng = () => {
  const [t] = useTranslation("global");
  return (
    <div id="bullyng">
      <div className="bullyng">
        <Container fluid>
          <Row>
            <Col xs={12} md={2} className="d-sm-block"></Col>
            <Col xs={12} md={4}>
              <Fade left duration={2000}>
                <Image className="float-right responsivo" alt="Bullyng" src={BullyImg} />
              </Fade>
            </Col>
            <Col xs={12} md={4}>
              <Fade clear duration={1000}>
                <h1>ANTI-BULLYING</h1>
                <p>
                  {t("bull.phrase1")}
                </p>
                <div className="d-flex align-items-center justify-content-center text-center not-found-container">
                  <a href={pdf} rel="noreferrer" target="_BLANK">
                    <Button variant="light" size="lg" className="boton">{t("bull.button")}</Button>
                  </a>
                </div>
              </Fade>
            </Col>
            <Col xs={12} md={2}></Col>            
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Bullyng;