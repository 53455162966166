import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./carousal.css";

import  Slide1  from "../../assets/img/meet/img01.jpg";
import  Slide2  from "../../assets/img/meet/img02.jpg";
import  Slide3  from "../../assets/img/meet/img03.jpg";
import  Slide4  from "../../assets/img/meet/img04.jpg";
import  Slide5  from "../../assets/img/meet/img06.jpg";
import  Slide6  from "../../assets/img/meet/img07.jpg";




const Meet = () => {
    return (
        <div id="meet">
            <Carousel>
                <Carousel.Item>
                    <Row>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide1} />
                            </Card>                            
                        </Col>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide2} />
                            </Card>                            
                        </Col>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide3} />
                            </Card>                            
                        </Col>
                    </Row>                    
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide4} />
                            </Card>                            
                        </Col>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide5} />
                            </Card>                            
                        </Col>
                        <Col md={4}>
                            <Card>
                              <Card.Img variant="top" src={Slide6} />
                            </Card>                            
                        </Col>
                    </Row>
                </Carousel.Item>
            </Carousel>
            
        </div>
    )
}

export default Meet