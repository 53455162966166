import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Facebook from "../../assets/img/footer/facebook.png";
import Whatsapp from "../../assets/img/footer/whatsapp.png";
import Twitter from "../../assets/img/footer/twitter.png";
import Instagram from "../../assets/img/footer/instagram.png";
import Youtube from "../../assets/img/footer/youtube.png";
import "./footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <div id="footer">
      <div className="footer">
        <Container fluid>
          <Row>
            <Col xs={12} md={1} className="d-sm-block"/>
            <Col xs={12} md={2}>
              <h1>{t("footer.phrase1")}</h1>
              <h1>{t("footer.phrase2")}</h1>
              <h1>{t("footer.phrase3")}</h1>
            </Col>
            <Col xs={12} md={1}><br/></Col>
            <Col xs={12} md={4}>
              <h1>{t("footer.follow")}</h1>
              <Row>
                <Col xs={1} md={1} className="d-sm-block"/>
                <Col xs={2} md={2}>
                  <Image className="d-block mx-auto img-fluid img-responsive" alt="Facebook" src={Facebook} width={50} />
                </Col>
                <Col xs={2} md={2}>
                  <Image className="d-block mx-auto img-fluid img-responsive" alt="Whatsapp" src={Whatsapp} width={50} />
                </Col>
                <Col xs={2} md={2}>
                  <Image className="d-block mx-auto img-fluid img-responsive" alt="Twitter" src={Twitter} width={50} />
                </Col>
                <Col xs={2} md={2}>
                  <Image className="d-block mx-auto img-fluid img-responsive" alt="Instagram" src={Instagram} width={50} />
                </Col>
                <Col xs={2} md={2}>
                  <Image className="d-block mx-auto img-fluid img-responsive" alt="Youtube" src={Youtube} width={50} />
                </Col>
                <Col xs={1} md={1} className="d-sm-block"/>
              </Row>              
            </Col>
            <Col xs={12} md={1}><br/></Col>
            <Col xs={12} md={2}>
              <h1>{t("footer.power")}<a href="http://indk.net"><b>{t("footer.indk")}</b></a></h1>
            </Col>
            <Col xs={12} md={1} className="d-sm-block"/>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;