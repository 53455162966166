import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./fun.css";
import FunIma from "../../assets/img/about/fun.png";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

const Fun = () => {
  const [t] = useTranslation("global");
  return (
    <div id="fun">
      <div className="fun">
        <Container fluid>
          <Row>
            <Col xs={12} md={2} className="d-sm-block"/>
            <Col xs={12} md={4}>
              <Fade left duration={2000}>
                <Image className="float-right responsivo" alt="Fun" src={FunIma} />
              </Fade>
            </Col>
            <Col xs={12} md={4}>
              <Fade clear duration={1000}>
                <h1>
                  {t("fun.phrase1")}
                  <br></br>
                  {t("fun.phrase2")}
                </h1>
                <p>
                  {t("fun.phrase3")}
                </p>
              </Fade>
            </Col>            
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Fun;