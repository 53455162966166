import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import "./carousal.css";
import Loading from "../spinner/loading";
import  Slide1 from  '../../assets/img/home/home01.jpg';
import  Slide2 from  "../../assets/img/home/home02.jpg";
import  Slide3 from  "../../assets/img/home/home03.jpg";
import { useTranslation } from "react-i18next";


const CustomCarousel = () => {
    const [t] = useTranslation("global");

    const [playAnimation, setPlayAnimation] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    if(!playAnimation){
        return <Loading/>
    }else{
        return (
            <div id="home">
                <Carousel fade variant="dark" controls={false} indicators={false} interval={1500}>
                    <Carousel.Item>
                        <img 
                            className="d-block w-100"
                            src={Slide1} 
                            alt="First slide"
                        />
                        <span className="letras">
                            {t("about.phrase1")}
                        </span>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Slide2}
                            alt="Third slide"
                        />
                        <span className="letras">
                            {t("skill.phrase1")} {t("skill.phrase2")}
                        </span>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block custom-img image-responsive"
                            src={Slide3}
                            alt="Third slide"
                        />
                        <span className="letras">
                            {t("about.phrase3")}
                        </span>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default CustomCarousel