import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import Flip from 'react-reveal/Flip';
import "./win.css";
import Winner from "../../assets/img/skills/winner.png";
import Medal from "../../assets/img/skills/medal.png";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

const Win = () => {
  const [t] = useTranslation("global");
  return (
    <div id="win">
      <div className="win">
        <Container fluid>
          <Row>
            <Col xs={12} md={1} className="d-sm-block"/>
            <Col xs={12} md={5}>
              <Fade clear duration={1000}>
                <Row>
                  <Col>
                    <Image className="mx-auto d-block responsivo" alt="Winners" src={Winner} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {t("win.phrase1")} <b>{t("win.phrase2")} </b> 
                      {t("win.phrase3")} <b>{t("win.phrase4")} </b>
                    </p>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col xs={12} md={4}>
              <Flip top>
                <Image className="float-left medal responsivo" alt="Medal" src={Medal} />
              </Flip>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Win;