import React from "react";
import "./App.css";
import NavBar from "./components/navBar/NavBar";
import CustomCarousel from "./components/carousel/Carousal";
import Video from "./views/video/Video"
import About from "./views/about/About";
import Fun from "./views/fun/Fun";
import Build from "./views/build/Build";
import Skill from "./views/skill/Skills";
import Win from "./views/win/Win";
import Meet from "./views/meet/Meet";
import Testi from "./views/testimonies/Testimonies";
import Bull from "./views/bullyng/Bullyng";
//import Covi from "./views/covid/Covid";
import Contact from "./components/contact/contact";
import Footer from "./views/footer/footer";
import { Parallax } from "react-parallax";
import Container from "react-bootstrap/Container";

function App() {
  return (
    <div className="App">
      <NavBar />
      <CustomCarousel />
      <div>
        <Parallax
          blur={{ min: -30, max: 30 }}
          bgImageAlt=""
          strength={-200}
        >
          <div>
            <Container fluid>
              <Video />
            </Container>
          </div>
          <div>
            <Container fluid>
              <About />              
            </Container>
          </div>
          <div>
            <Container fluid>
              <Fun />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Build />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Skill />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Win />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Meet />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Testi />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Bull />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Contact />
            </Container>
          </div>
          <div>
            <Container fluid>
              <Footer />
            </Container>
          </div>
        </Parallax>
      </div>
    </div>
  );  
}

export default App;
