import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./about.css";
import Kid from "../../assets/img/about/kid.png";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

const About = () => {
  const [t] = useTranslation("global");
  return (
    <div id="about">
      <div className="about">
        <Container fluid>
          <Row>
            <Col xs={12} md={2} className="d-sm-block"></Col>
            <Col xs={12} md={4}>
              <Fade fade duration={1000}>
                <h1>{t("about.about")} <b>{t("about.us")}</b></h1>
                <p>
                  <b>{t("about.phrase1")}</b><br></br>
                  {t("about.phrase2")}
                </p>
                <h2>
                  {t("about.phrase3")}
                </h2>
              </Fade>
            </Col>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={5}>
              <Fade right duration={2000}>
                <Image className="float-left responsivo" alt="About" src={Kid} />
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;