import React from "react";
import { useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./contact.css";
import Phone from "../../assets/img/contact/phone.png";
import Mapa from "../../assets/img/contact/mapa.png";
import Google from "../../assets/img/contact/google.png";
import Seal from "../../assets/img/contact/seal.png";
import Image from "react-bootstrap/Image";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [t] = useTranslation("global");
  
  const enviando = () => toast("Enviando correo");
  const exito = () => toast("Enviando con exito");
  const error = (error) => toast("Error enviando intente de nuevo "+ error);

  var fec = new Date();
  var ano = fec.getFullYear();
  var mes = fec.getMonth() + 1;
  var kei = btoa(ano.toString()+mes.toString()+'OTT'+mes.toString()+ano.toString());
  
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  };

  const handleSubmit = (event) => {
    event.preventDefault();    
    enviando();
    axios.post('https://wsott.ott-usa.com/EMA.php?t=mx&x='+kei, inputs).then(function(response){
        console.log(response.data);
        if(response.data[0].estado === '200'){
          exito();
        }else{
          error(response.data[0].resultado);
        }
    });
  };  
  
  return (
    <div id="contact">
      <div className="contact">
        <Container fluid>
          <Row>
            <Col xs={12} md={2} className="d-sm-block"/>
            <Col xs={12} md={3}>
              <Fade clear duration={1000}>
                <h1>{t("contact.title")}<b>{t("contact.bold")}</b></h1>
                <p>
                  <b>{t("contact.phrase1")}</b>
                  <br/>
                  <br/>
                  {t("contact.phrase2")}
                </p>
                <form className="field">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("contact.input1")}
                      aria-label={t("contact.input1")}
                      aria-describedby={t("contact.input1")}
                      id="nom"
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("contact.input2")}
                      aria-label={t("contact.input2")}
                      aria-describedby={t("contact.input2")}
                      id="tel"
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("contact.input3")}
                      aria-label={t("contact.input3")}
                      aria-describedby={t("contact.input3")}
                      id="cor"
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <Form.Control 
                      as="textarea" 
                      placeholder={t("contact.input4")}
                      aria-label={t("contact.input4")}
                      aria-describedby={t("contact.input4")}
                      rows={3}
                      id="mes"
                      onChange={handleChange} 
                    />
                  </InputGroup>

                  <div className="d-flex align-items-center justify-content-center text-center not-found-container">
                    <Button variant="danger" size="lg" onClick={handleSubmit} className="boton">{t("contact.button")}</Button>
                  </div>
                </form>

                <ToastContainer
                  position="bottom-left"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />

              </Fade>
            </Col>
            <Col xs={12} md={1} className="d-sm-block"/>
            <Col xs={12} md={4}>
              <Fade right duration={2000}>
                <Row className="justify-content-md-center">
                  <Col xs={12} md={12}>
                    <Image id="pho" alt="Phone" src={Phone} className="d-block mx-auto img-fluid" />  
                    <Image id="mapa" alt="About" src={Mapa} className="d-block mx-auto img-fluid" />
                    <a href="https://goo.gl/maps/H2SzD9var2vedeSr8" target="_BLANK" rel="noreferrer" >
                      <Image id="goo" alt="Google" src={Google} className="d-block mx-auto img-fluid" />
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={3}>
                    <Image className="d-block mx-auto img-fluid" alt="Seal" src={Seal} />
                  </Col>
                  <Col xs={12} md={9}>
                    <h2>{t("contact.phrase3")}</h2>
                    <p id="sub">{t("contact.phrase4")} <b className="red">{t("contact.phrase5")}</b></p>
                  </Col>
                </Row>                
              </Fade>
            </Col>
            <Col xs={12} md={2} className="d-sm-block"/>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;