import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./testimonies.css";
//import StarIcon from '@material-ui/icons/Star';
//import PersonIcon from '@material-ui/icons/Person';
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Testimonies = () => {
  const [t] = useTranslation("global");
  return (
    <div id="testimonies">
      <div className="testimonies">
        <Container fluid>
          {/*
          <Row>
            <Col xs={12} md={12}>
              <h1>{t("testi.title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={1} md={3}/>
            <Col xs={1} md={6}>
              <Row>
                <Col xs={2} md={3}>
                  <PersonIcon className="person"/>
                </Col>
                <Col xs={10} md={9}>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a ligula nec arcu mollis ultrices. 
                    Sed laoreet turpis vel diam luctus, vel viverra dui euismod. Sed volutpat, nisi quis placerat accumsan, metus mauris ornare turpis, nec rhoncus velit enim sit amet leo.
                  </p>
                  <h2>Name of the champion/autor</h2>
                  <StarIcon className="star"/>
                  <StarIcon className="star"/>
                  <StarIcon className="star"/>
                  <StarIcon className="star"/>
                  <StarIcon className="star"/>
                </Col>
              </Row>
            </Col>
            <Col xs={1} md={3}/>
          </Row>*/}
          <Row>
            <Col xs={1} md={3}/>
            <Col xs={12} md={6}>
              <hr/>
              <p>
                {t("testi.phrase1")}
                <b> {t("testi.phraseB")}</b>
              </p>
              <p>
                <sub>{t("testi.phrase2")}</sub>
              </p>
              <div className="d-flex align-items-center justify-content-center text-center not-found-container">
                <a href="#contact">
                  <Button variant="danger" size="lg" className="boton">{t("testi.button")}</Button>
                </a>
              </div>
            </Col>
            <Col xs={1} md={3}/>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Testimonies;