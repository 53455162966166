import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import "./meet.css";
import Carrusel from '../../components/carousel/Meet';
import { useTranslation } from "react-i18next";


const Meet = () => {
  const [t] = useTranslation("global");
  return (
    <div id="meet">
      <div className="meet">
        <Container fluid>
          <Row>
            <Col xs={12} md={12}>
              <h1>{t("meet.title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={1} md={3}/>
            <Col xs={10} md={6}>
              <Fade clear>
                <Carrusel/>
              </Fade>
            </Col>
            <Col xs={1} md={3}/>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Meet;