import React from "react";
import { Spinner } from "reactstrap";
import "./loading.css";

const Loading = () => {
    return (
        <div className="divPadre">
            <div className="divHijo">
                <Spinner 
                    color="danger"
                    //animation="grow"
                    type="grow"
                    style={{
                        height: '10rem',
                        width: '10rem',
                        border: '10px solid currentColor !important',
                        borderRightColor: 'transparent !important'
                      }}>
                    {' '}
                </Spinner>
            </div>            
        </div>        
    );
}

export default Loading