import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Logo from "../../assets/icons/logo_white.png";
import "./NavBar.css";
import { useTranslation } from "react-i18next";

 const NavBar = () => {
    const [t, i18n] = useTranslation("global");
    const lan = t("menu.lang");

    function lanEsp(lan){
        if(lan === "Español"){ return true; }
        if(lan === "English"){ return false; }
    }

    function lanEng(lan){
        if(lan === "Español"){ return false; }
        if(lan === "English"){ return true; }
    }
    

    return (
        <>
            <Navbar expand="md" fixed="top" className="animate-navbar nav-theme" variant="dark">
                <Container>
                    <Navbar.Brand href="#home">
                        <img className="logo" src={Logo} alt='Logo'/>
                    </Navbar.Brand>  
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home" className="anim-underline-fx">{t("menu.home")}</Nav.Link>
                            <Nav.Link href="#about" className="anim-underline-fx">{t("menu.about")}</Nav.Link>
                            {/*<Nav.Link href="#covid" className="anim-underline-fx">{t("menu.covid")} <br/>{t("menu.measures")}</Nav.Link>*/}
                            {/*<Nav.Link href="#gallery" className="anim-underline-fx">{t("menu.galery")}</Nav.Link>*/}
                            <Nav.Link href="#contact" className="anim-underline-fx">{t("menu.contact")}<br/>{t("menu.free")}</Nav.Link>
                            <Nav.Link href="#" style={lanEsp(lan) ? {} : { display: 'none' }}  className="anim-underline-fx" onClick={() => i18n.changeLanguage("es") }>{lan}</Nav.Link>
                            <Nav.Link href="#" style={lanEng(lan) ? {} : { display: 'none' }} className="anim-underline-fx" onClick={() => i18n.changeLanguage("en") }>{lan}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar