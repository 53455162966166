import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components'
import { theme } from './components/theme/theme'
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "./language/en/global.json";
import global_es from "./language/es/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources:{
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </ThemeProvider>,
    document.getElementById('root')
);


