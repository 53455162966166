import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import Pulse from 'react-reveal/Pulse';
import "./build.css";
import Kids from "../../assets/img/build/kids.png";
import Icon from "../../assets/img/build/icon.png";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Build = () => {
  const [t] = useTranslation("global");
  return (
    <div id="build">
      <div className="build">
        <Container fluid>
          <Row>
            <Col xs={12} md={2} className="d-sm-block"></Col>
            <Col xs={12} md={4}>
              <Fade clear duration={1000}>
                <h1>{t("build.build")}<br/> {t("build.strong")} <b>{t("build.atlethes")}</b></h1>
                <p>
                  {t("build.phrase1")}
                  <b> {t("build.phrase2")}</b>
                </p>
              </Fade>
            </Col>
            <Col xs={12} md={1} className="d-sm-block"></Col>
            <Col xs={12} md={5}>
              <Fade right duration={2000}>
                <Image className="float-left responsivo" alt="About" src={Kids} />
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Pulse duration={9000}> 
                <h3><Image className="logo" alt="Logo" src={Icon}/>{t("build.phrase3")}<b>!</b></h3>
                <div className="d-flex align-items-center justify-content-center text-center not-found-container">
                  <a href="#contact">
                    <Button variant="danger" size="lg" className="boton">{t("build.contact")}</Button>
                  </a>
                </div>
              </Pulse>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Build;